import {FitGenieLogoIcon, FitGenieStarIcon, MenuIcon} from "../icons";
import {Button} from "../buttons/Button";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {toggleSideNavOpen} from "../../redux/navigation/navigationSlice";
import Link from 'next/link'
import {NavBarHeight} from "./NavBarMain";
import {selectLoginStatus} from "../../redux/auth/authSlice";
import {useModalRouting} from "../../utils/useModalRouting";
import {useRouter} from "next/router";

export const NavBarLanding: React.FC<any> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const loginStatus = useAppSelector(selectLoginStatus);
  const { createRoute } = useModalRouting()
  const router = useRouter()

  return (
    <>
      <div className="w-full mx-auto px-2 sm:px-8 md:px-10 bg-transparent z-10">
        <div className={`relative flex items-center justify-between ${NavBarHeight}`}>
          <div className="flex items-center justify-start space-x-2 md:space-x-6 lg:space-x-8">
            <button
              className="p-3 transition-all hover:bg-fg-gray-surface rounded-full flex items-center justify-center"
              onClick={() => dispatch(toggleSideNavOpen())}
            >
              <MenuIcon className="block h-5 w-5" />
            </button>

            <div className="flex-shrink-0 flex items-center">
              <Link href="/" passHref>
                <a>
                  <FitGenieStarIcon className="block md:hidden h-7 w-auto" />
                </a>
              </Link>
              <Link href="/" passHref>
                <a>
                  <FitGenieLogoIcon className="hidden md:block h-7 w-auto" />
                </a>
              </Link>
            </div>
          </div>

          <div className={`inset-y-0 right-0 flex items-center space-x-2 sm:static sm:inset-auto ${loginStatus === 'logged_in' || loginStatus === 'logged_in_anon' ? 'hidden' : ''}`}>
            <Link
              href={createRoute({ name: 'login', query: { config: 'sign_in' } })}
              shallow={true}
              passHref
            >
              <a>
                <Button
                  buttonStyle="text_secondary"
                  size="md"
                >
                  { t('auth:auth_title_sign_in') }
                </Button>
              </a>
            </Link>

            <Link
              href={createRoute({ name: 'login', query: { config: 'sign_up' } })}
              shallow={true}
              passHref
            >
              <a>
                <Button
                  buttonStyle="primary"
                  size="md"
                >
                  { t('auth:auth_title_sign_up') }
                </Button>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}