import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  fetchAutocompletePredictions,
  fetchCurrentLocation,
  fetchLocationFromPlaceId,
  locationSearchFocused,
  LocationSearchItem,
  selectInputAddress,
  selectIsSearching,
  selectItems,
  selectListIsOpen
} from "../../redux/location/locationSearchSlice";
import {AutoCompleteInput, AutocompleteItem} from "../inputs/AutoCompleteInput";
import {Heading4, Hero, HeroBody, HeroHeading2, LocalizedTextLabel, Paragraph} from "../text";
import {
  ArrowBackExtendedIcon,
  ArrowNextExtendedIcon,
  LoadingSpinnerIcon,
  MarkerFilledIcon,
  NavigationFilledIcon,
  SearchIcon,
  StarColorIcon,
  StarIcon,
  WarningFilledIcon
} from "../icons";
import {HeroBodyInput} from "../inputs";
import {AutocompletePrediction} from "googlemaps";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";
import {Container} from "../containers/Container";
import {LocalizationInfo} from "react-i18next";
import {Button} from "../buttons/Button";
import {Controller, Grid, Navigation, Pagination, Scrollbar, Swiper as SwiperClass} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {NavigationOptions} from "swiper/types";
import {LandingTransformationsView} from "./LandingTransformationsView";
import {useRouter} from "next/router";

export const LandingView: React.FC<any> = () => {
  const { t } = useTranslation()

  const isOpen = useAppSelector(selectListIsOpen)

  const dispatch = useAppDispatch();
  const router = useRouter();
  const items = useAppSelector(selectItems);
  const isSearching = useAppSelector(selectIsSearching);
  const inputAddress = useAppSelector(selectInputAddress);

  const autoCompleteItems: AutocompleteItem<LocationSearchItem>[] = items.map(item => {
    return {
      id: item.id,
      value: item
    }
  })

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const features: { id: string, title: LocalizationInfo | string, description: LocalizationInfo | string, imagePath: string }[] = [
    {
      title: 'Get a custom macro plan',
      id: 'macro_plan',
      description: 'Receive a personalized macro plan built to match your body type and activity level, while ensuring you reach your goal on time.',
      imagePath: '../images/landing_graphic_macro_plan.webp'
    },
    {
      title: 'Let us do your meal prep',
      id: 'meal_prep',
      description: 'Dine on chef-prepared meals crafted to help follow your macro plan. No more cooking, cleaning, or packing. It all gets sent to your house.',
      imagePath: '../images/landing_graphic_meal_delivery.webp'
    },
    {
      title: 'Optimize your plan with our diet coach',
      id: 'diet_coach',
      description: 'Check in with your virtual diet coach weekly to ensure you’re on the right track.',
      imagePath: '../images/landing_graphic_diet_coach.webp'
    },
    {
      title: 'Track & scan millions of foods',
      id: 'barcode_scanning',
      description: 'Track your macros with our massive food database & barcode scanner.',
      imagePath: '../images/landing_graphic_barcode_scan.webp'
    },
    {
      title: 'Learn about macros with free courses ',
      id: 'courses',
      description: 'Take our free course to learn everything you need to know about macros.',
      imagePath: '../images/landing_graphic_course.webp'
    },
    {
      title: 'Enjoy hundreds of macro-friendly recipes',
      id: 'recipes',
      description: 'Feast on hundreds of mouth-watering recipes and meal plans designed with your macros in mind.',
      imagePath: '../images/landing_graphic_recipe.webp'
    }
  ]

  const reviews: { id: string, name: LocalizationInfo | string, details: LocalizationInfo | string, title: LocalizationInfo | string, photo: string }[] = [
    {
      id: 'nicole',
      name: 'Nicole',
      details: 'If you’re going to track macros and adopt a flexible eating lifestyle, there’s only one way to do it–FitGenie. I credit the app not only with delivering my best physique to date, but also empowering my clients to fuel their endeavors enjoyably while reaping impressive aesthetic result',
      title: 'FUH-REACKING AMAZING',
      photo: '../images/landing_photo_nicole.webp'
    },
    {
      id: 'nolan',
      name: 'Nolan',
      details: 'FitGenie has definitely changed my life. The app helped me with tracking my meals and suggesting new ones I should eat. Each effort to improve the product has been amazing. I recommend this product to everyone that I know.',
      title: 'FitGenie changed my life',
      photo: '../images/landing_photo_nolan.webp'
    },
    {
      id: 'arthi',
      name: 'Arthi',
      details: 'Macro-tracking has been an essential part of my journey as a strength athlete. It helps ensure I have the proper nutrients to fuel my training, and also helps me stay within my weight class for competitions. If you’re new to the concept, FitGenie makes it clear and easy.',
      title: 'Clear and easy',
      photo: '../images/landing_photo_arthi.webp'
    }
  ]

  const nextRef = useRef(null)
  const prevRef = useRef(null)

  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass>();
  const [reviewIndex, setReviewIndex] = useState<number>(0);

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Landing' }))
  }, [])

  const LandingButtons = ({ className }: { className?: string }) => {
    return <div className={`w-full max-w-xl grid grid-cols-1 xl:grid-cols-2 mx-auto gap-4 ${className ? className : ''}`}>
      <div className="w-full flex flex-col">
        <div className="">
          <Button
            onClick={() => { router.push('/macro-survey') }}
            isFullWidth size="xl"
          >Get A Custom Plan</Button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="">
          <Button
            onClick={() => { router.push('/store-feed') }}
            isFullWidth
            buttonStyle="secondary"
            size="xl"
          >Shop Meals</Button>
        </div>
      </div>
    </div>
  }

  return <>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 justify-items-center lg:justify-items-start flex-grow items-center px-4 sm:px-8 lg:px-10 mt-4 md:mt-6 lg:-mt-20">
      <div className="text-center lg:text-left mb-10 lg:mb-0">
        <Hero className="mb-6">
          { t('landing:landing_hero_title') }
        </Hero>
        <HeroBody className="mb-12">
          { t('landing:landing_hero_subtitle') }
        </HeroBody>

        <LandingButtons className="mx-0" />

        <div className="flex items-center hidden">
          <div className="flex-grow max-w-xl lg:mr-3 text-left">
            <AutoCompleteInput
              t={t}
              isOpen={isOpen}
              inputValue={inputAddress}
              items={autoCompleteItems}
              onFocus={() => {
                dispatch(locationSearchFocused())
              }}
              renderItem={ (item, index) => {
                return (
                  <div className={`px-4 py-3 flex items-center ${index !== autoCompleteItems.length - 1 ? 'border-b border-fg-gray-surface' : ''}`}>
                    <div>
                      {
                        (() => {
                          if (item.value.isLoading) {
                            return <LoadingSpinnerIcon className="h-6 h-6 mr-4 animate-spin text-fg-primary" />
                          } else if (item.value.type === 'error') {
                            return <WarningFilledIcon className="h-6 w-6 mr-4" />
                          } else if (item.value.type === 'current_location') {
                            return <NavigationFilledIcon className="text-fg-primary h-6 w-6 mr-4" />
                          } else if (item.value.type === 'no_results') {
                            return <SearchIcon className="h-6 w-6 mr-4" />
                          } else {
                            return <MarkerFilledIcon className="h-6 w-6 mr-4" />
                          }
                        })()
                      }
                    </div>
                    <div className="flex-grow">
                      <HeroBody className={`${item.value.subtitle ? 'mb-1' : ''}`}>
                        <LocalizedTextLabel t={t} text={item.value.title} />
                      </HeroBody>
                      <Paragraph className={`${item.value.subtitle ? 'block' : 'hidden'}`}>
                        <LocalizedTextLabel t={t} text={item.value.subtitle} />
                      </Paragraph>
                    </div>
                  </div>
                )
              }}
              renderInput={reqProps => {
                // @ts-ignore
                return <HeroBodyInput
                  autoComplete="off"
                  leadingIcon={isSearching ? <LoadingSpinnerIcon className="animate-spin text-fg-primary" /> : <MarkerFilledIcon />}
                  trailingIcon={
                    <div className="cursor-pointer lg:hidden rounded-full !h-8 !w-8 !p-2 flex items-center justify-center bg-fg-primary text-white">
                      <img className="text-white h-4 w-4" src="../images/common_arrow_next_extended_white.svg" />
                    </div>
                  }
                  placeholder={ t('landing:landing_placeholder_enter_address') }
                  {...reqProps}
                />
              }}
              onItemSelected={item => {
                if (item.value.type === 'prediction') {
                  const prediction = item.value.value as AutocompletePrediction
                  dispatch(fetchLocationFromPlaceId({ placeId: prediction.place_id, save: true, url: '/store-feed' }))
                } else if (item.value.type === 'current_location') {
                  dispatch(fetchCurrentLocation({ save: true, url: '/store-feed' }))
                }

                return { shouldDismiss: false }
              }}
              onTextChanged={(text) => {
                dispatch(fetchAutocompletePredictions(text))
              }}
            />
          </div>

          <button className="hidden lg:block rounded-full h-12 w-12 p-3 flex items-center justify-center bg-fg-primary text-white">
            <ArrowNextExtendedIcon />
          </button>
        </div>
      </div>
      <div className="relative min-w-screen lg:min-w-[calc(50vw-3.25rem)] max-h-screen lg:min-h-screen">
        <div className="relative h-screen max-h-screen-2/3 lg:max-h-screen w-full flex flex-col justify-start lg:justify-center items-center lg:items-start my-8 lg:my-0">
          <img
            src="../images/landing_graphic_bison_meatballs.webp"
            className={`absolute inset-y-1/4 transition-opacity transform -translate-x-1/2 inset-x-1/10 md:inset-x-1/8 lg:inset-x-1/8 w-56 lg:w-72 object-contain`}
          />

          <img
            src="../images/landing_graphic_shrimp_and_grits.webp"
            className={`absolute inset-y-3/5 transition-opacity transform -translate-x-1/2 inset-x-9/10 md:inset-x-7/8 lg:inset-x-9/10 w-56 lg:w-72 object-contain`}
          />

          <img
            src="../images/landing_graphic_weekly_update.webp"
            className={`absolute inset-x-1/2 transform -translate-x-1/2 drop-shadow-2xl transition-opacity max-h-screen-2/3 lg:max-h-screen-3/4 object-contain lg:w-2/3 ${isImageLoaded ? 'opacity-100' : 'opacity-0'}`}
            onLoad={() => {
              setIsImageLoaded(true)
            }}
          />

          <img
            src="../images/landing_graphic_mango.webp"
            className={`absolute inset-y-2/3 transition-opacity inset-x-1/10 md:inset-x-1/8 lg:inset-x-1/20 w-28 lg:w-32 object-contain`}
          />

          <img
            src="../images/landing_graphic_rosemary.webp"
            className={`invisible lg:visible absolute inset-y-1/8 transition-opacity inset-x-1/10 md:inset-x-1/8 lg:inset-x-0 w-8 lg:w-12 object-contain`}
          />

          <img
            src="../images/landing_graphic_bell_pepper.webp"
            className={`absolute inset-y-1/8 transition-opacity transform -translate-x-1/2 inset-x-9/10 md:inset-x-7/8 lg:inset-x-9/10 w-28 lg:w-32 object-contain`}
          />

          <img
            src="../images/landing_graphic_orange.webp"
            className={`absolute inset-y-9/20 transition-opacity transform -translate-x-1/2 inset-x-3/4 md:inset-x-2/3 lg:inset-x-3/4 w-32 lg:w-36 object-contain`}
          />

          <img
            src="../images/landing_graphic_herb.webp"
            className={`invisible lg:visible absolute inset-y-5/6 transition-opacity transform -translate-x-1/2 inset-x-1/2 md:inset-x-1/2 lg:inset-x-1/2 w-20 lg:w-24 object-contain`}
          />
        </div>
      </div>
    </div>
    <Container className="mt-8 lg:mt-0 flex flex-col space-y-12 lg:space-y-16 xl:space-y-20">
      <Hero className="text-center">
        Burn fat & build muscle with <span className="text-fg-primary font-bold">macros & more.</span>
      </Hero>

      <div className="grid grid-cols-1 gap-8 lg:gap-12 md:grid-cols-2 lg:grid-cols-3">
        {features.map((feature) => {
          return <div key={feature.id} className="flex flex-col items-center">
            <img
              src={feature.imagePath}
              className={`w-full h-28 md:h-36 object-contain mb-6 lg:mb-8`}
            />
            <HeroHeading2 className="text-center font-bold mb-4 lg:mb-6">
              <LocalizedTextLabel t={t} text={feature.title}/>
            </HeroHeading2>
            <HeroBody className="text-center">
              <LocalizedTextLabel t={t} text={feature.description}/>
            </HeroBody>
          </div>
        })}
      </div>

      <LandingButtons />

      <Hero className="text-center">
        Our transformations are <span className="text-fg-primary font-bold">mind-blowing.</span>
      </Hero>

      <LandingTransformationsView />

      <LandingButtons />
    </Container>
    <div className="mt-8 lg:mt-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 2xl:grid-cols-3 gap-y-10">
      <div className="col-span-1 lg:col-span-3 2xl:col-span-2 text-center md:text-left flex flex-col space-y-10 lg:space-y-10 xl:space-y-16 self-center px-4 sm:px-6 lg:px-10">
        <Hero>Join thousands of <span className="font-bold text-fg-primary">success stories.</span></Hero>

        <Swiper
          onInit={(swiper) => {
            (swiper.params.navigation as NavigationOptions).prevEl = prevRef.current;
            (swiper.params.navigation as NavigationOptions).nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          className="w-full"
          modules={[Navigation, Grid, Pagination, Scrollbar, Controller]}
          onSwiper={swiper => {
            setControlledSwiper(swiper)
          }}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSlideChange={(swiper) => {
            setReviewIndex(swiper.activeIndex)
          }}
        >
          {reviews.map((i, index) => {
            return <SwiperSlide key={i.id + '_' + index.toString()}>
              <div key={i.id + '_' + index.toString()} className="flex flex-col space-y-6">
                <img className="h-20 w-20 rounded-full object-contain bg-fg-red md:mx-0 mx-auto" src={i.photo} />

                <HeroBody><span className="font-bold"><LocalizedTextLabel t={t} text={i.name} /></span></HeroBody>

                <HeroHeading2><span className="font-bold"><LocalizedTextLabel t={t} text={i.title} /></span></HeroHeading2>

                <div className="flex space-x-1 md:mx-0 mx-auto">
                  {[1, 2, 3, 4, 5].map((i) => {
                    return <StarColorIcon key={i} className="h-4 w-4 lg:h-6 lg:w-6" />
                  })}
                </div>

                <HeroBody><LocalizedTextLabel t={t} text={i.details} /></HeroBody>
              </div>
            </SwiperSlide>
          })}
        </Swiper>

        <div className="flex items-center space-x-4 md:mx-0 mx-auto">
          <div
            onClick={(e) => {
              e.stopPropagation()
              const currentIndex = controlledSwiper?.activeIndex ?? 0
              const slidesPerView = controlledSwiper?.params.slidesPerView as number ?? 0
              let nextIndex = currentIndex - slidesPerView
              nextIndex = nextIndex < 0 ? reviews.length - 1 : nextIndex > reviews.length - 1 ? 0 : nextIndex
              controlledSwiper?.slideTo(nextIndex)
            }}
            className={`cursor-pointer bg-fg-primary-lightest flex items-center justify-center w-10 h-10 lg:w-14 lg:h-14 p-2 lg:p-3.5 m-auto rounded-full`}
            ref={prevRef}>
            <ArrowBackExtendedIcon className="h-4 w-4 lg:h-6 lg:w-6 text-fg-primary" />
          </div>

          {reviews.map((review, index) => {
            return <div key={review.id} className={`rounded-full h-2.5 w-2.5 ${index === reviewIndex ? 'bg-fg-primary' : 'bg-fg-gray-surface'}`} />
          })}

          <div
            onClick={(e) => {
              e.stopPropagation()
              const currentIndex = controlledSwiper?.activeIndex ?? 0
              const slidesPerView = controlledSwiper?.params.slidesPerView as number ?? 0
              let nextIndex = currentIndex + slidesPerView
              nextIndex = nextIndex < 0 ? reviews.length - 1 : nextIndex > reviews.length - 1 ? 0 : nextIndex
              controlledSwiper?.slideTo(nextIndex)
            }}
            className={`cursor-pointer bg-fg-primary-lightest flex items-center justify-center w-10 h-10 lg:w-14 lg:h-14 p-2 lg:p-3.5 m-auto rounded-full`}
            ref={nextRef}>
            <ArrowNextExtendedIcon className="h-4 w-4 lg:h-6 lg:w-6 text-fg-primary" />
          </div>

          <div className="flex-grow" />
        </div>
      </div>
      <div className="relative w-full self-center col-span-1 lg:col-span-2 2xl:col-span-1">
        <div className="aspect-w-3 aspect-h-4">
          <img className="h-full w-full md:rounded-bl-xl md:rounded-tl-xl object-cover" src="../images/landing_photo_booth.webp" />

          <div className="flex absolute top-6/7 left-1/10 max-h-20 lg:max-h-24 transform -translate-y-1/2">
            <div className="flex items-center bg-fg-black rounded-full p-3 space-x-3 shadow-lg">
              <div className="bg-white rounded-full">
                <img className="h-14 w-14 p-1 lg:h-16 lg:w-16 lg:p-2" src="../images/common_logo_fitgenie_star.svg" />
              </div>
              <div className="flex flex-col space-y-0 lg:-space-y-0.5">
                <Heading4 className="text-white">FitGenie</Heading4>

                <div className="flex space-x-0 items-center">
                  {[1, 2, 3, 4, 5].map((i) => {
                    return <StarIcon key={i} className="h-3 w-3 lg:h-3.5 lg:w-3.5 text-fg-gold" />
                  })}
                  <Heading4 className="text-white pl-2">4.7</Heading4>
                </div>

                <Heading4 className="text-white">1400+ ratings</Heading4>
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Container className="mt-8 flex flex-col space-y-12 lg:space-y-16 xl:space-y-20 mb-8">
      <Hero className="text-center">
        Ready to start <span className="text-fg-primary font-bold">your own transformation?</span> Get started today.
      </Hero>

      <LandingButtons />
    </Container>
  </>
}