import React, {useEffect, useState} from "react";
import {useTranslation} from "next-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
  fetchAutocompletePredictions,
  fetchCurrentLocation,
  fetchLocationFromPlaceId,
  locationSearchFocused,
  LocationSearchItem,
  selectInputAddress,
  selectIsSearching,
  selectItems,
  selectListIsOpen
} from "../../redux/location/locationSearchSlice";
import {AutoCompleteInput, AutocompleteItem} from "../inputs/AutoCompleteInput";
import {Hero, HeroBody, LocalizedTextLabel, Paragraph} from "../text";
import {
  ArrowNextExtendedIcon,
  LoadingSpinnerIcon,
  MarkerFilledIcon,
  NavigationFilledIcon,
  SearchIcon,
  WarningFilledIcon
} from "../icons";
import {HeroBodyInput} from "../inputs";
import {AutocompletePrediction} from "googlemaps";
import {EventLogger} from "../../utils/eventLogger";
import {AnalyticsEvent} from "../../utils/eventProperties";


export const ShopLandingView: React.FC<any> = () => {
  const { t } = useTranslation()

  const isOpen = useAppSelector(selectListIsOpen)

  const dispatch = useAppDispatch();
  const items = useAppSelector(selectItems);
  const isSearching = useAppSelector(selectIsSearching);
  const inputAddress = useAppSelector(selectInputAddress);

  const autoCompleteItems: AutocompleteItem<LocationSearchItem>[] = items.map(item => {
    return {
      id: item.id,
      value: item
    }
  })

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  useEffect(() => {
    EventLogger.logEvent(new AnalyticsEvent({ eventName: 'Screen Visited', screenName: 'Landing' }))
  }, [])

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 justify-items-center lg:justify-items-start flex-grow items-center px-2 sm:px-8 lg:px-10 mt-4 md:mt-6 lg:-mt-20">
      <div className="text-center lg:text-left mb-10 lg:mb-0">
        <Hero className="mb-6">
          { t('landing:landing_hero_title') }
        </Hero>
        <HeroBody className="mb-12">
          { t('landing:landing_hero_subtitle') }
        </HeroBody>

        <div className="flex items-center">
          <div className="flex-grow max-w-xl lg:mr-3 text-left">
            <AutoCompleteInput
              t={t}
              isOpen={isOpen}
              inputValue={inputAddress}
              items={autoCompleteItems}
              onFocus={() => {
                dispatch(locationSearchFocused())
              }}
              renderItem={ (item, index) => {
                return (
                  <div className={`px-4 py-3 flex items-center ${index !== autoCompleteItems.length - 1 ? 'border-b border-fg-gray-surface' : ''}`}>
                    <div>
                      {
                        (() => {
                          if (item.value.isLoading) {
                            return <LoadingSpinnerIcon className="h-6 h-6 mr-4 animate-spin text-fg-primary" />
                          } else if (item.value.type === 'error') {
                            return <WarningFilledIcon className="h-6 w-6 mr-4" />
                          } else if (item.value.type === 'current_location') {
                            return <NavigationFilledIcon className="text-fg-primary h-6 w-6 mr-4" />
                          } else if (item.value.type === 'no_results') {
                            return <SearchIcon className="h-6 w-6 mr-4" />
                          } else {
                            return <MarkerFilledIcon className="h-6 w-6 mr-4" />
                          }
                        })()
                      }
                    </div>
                    <div className="flex-grow">
                      <HeroBody className={`${item.value.subtitle ? 'mb-1' : ''}`}>
                        <LocalizedTextLabel t={t} text={item.value.title} />
                      </HeroBody>
                      <Paragraph className={`${item.value.subtitle ? 'block' : 'hidden'}`}>
                        <LocalizedTextLabel t={t} text={item.value.subtitle} />
                      </Paragraph>
                    </div>
                  </div>
                )
              }}
              renderInput={reqProps => {
                // @ts-ignore
                return <HeroBodyInput
                  autoComplete="off"
                  leadingIcon={isSearching ? <LoadingSpinnerIcon className="animate-spin text-fg-primary" /> : <MarkerFilledIcon />}
                  trailingIcon={
                    <div className="cursor-pointer lg:hidden rounded-full !h-8 !w-8 !p-2 flex items-center justify-center bg-fg-primary text-white">
                      <img className="text-white h-4 w-4" src="../images/common_arrow_next_extended_white.svg" />
                    </div>
                  }
                  placeholder={ t('landing:landing_placeholder_enter_address') }
                  {...reqProps}
                />
              }}
              onItemSelected={item => {
                if (item.value.type === 'prediction') {
                  const prediction = item.value.value as AutocompletePrediction
                  dispatch(fetchLocationFromPlaceId({ placeId: prediction.place_id, save: true, url: '/store-feed' }))
                } else if (item.value.type === 'current_location') {
                  dispatch(fetchCurrentLocation({ save: true, url: '/store-feed' }))
                }

                return { shouldDismiss: false }
              }}
              onTextChanged={(text) => {
                dispatch(fetchAutocompletePredictions(text))
              }}
            />
          </div>

          <button className="hidden lg:block rounded-full h-12 w-12 p-3 flex items-center justify-center bg-fg-primary text-white">
            <ArrowNextExtendedIcon />
          </button>
        </div>
        {/*<div className="h-screen-1/8">*/}

        {/*</div>*/}
      </div>
      <div>
        <img
          src="../images/landing_graphic_meals_light_web.webp"
          className={`transition-opacity max-h-screen w-full object-contain ${isImageLoaded ? 'opacity-100' : 'opacity-0'}`}
          onLoad={() => {
            setIsImageLoaded(true)
          }}
        />
      </div>
    </div>
  )
}