import React from "react";
import {LocalizationInfo} from "react-i18next";
import {HeroBody, HeroHeading2, LocalizedTextLabel} from "../text";
import {FitGenieLogoIcon, LandingQuoteLeftGraphic} from "../icons";
import {useTranslation} from "next-i18next";

export const LandingTransformationsView: React.FC<any> = () => {
  const { t } = useTranslation()

  const transformations: { id: string, name: LocalizationInfo | string, quote: LocalizationInfo | string, before: string, after: string, change: string }[] = [
    {
      id: 'obi',
      name: 'Obi, 23 (FitGenie Co-Founder)',
      quote: 'I wanted to get in shape, so I built FitGenie to make it easier because other macro trackers were trash. I followed it for 12 weeks, lost 27 lbs, and got in the best shape of my life. From the custom macro plans to meal delivery, FitGenie has been an absolute game-changer for me.',
      before: '../images/landing_photo_obi_before.webp',
      after: '../images/landing_photo_obi_after.webp',
      change: '-27 lbs'
    },
    {
      id: 'kristen',
      name: 'Kristen, 24',
      quote: 'I used FitGenie to help me stay on-track and provide updated macros for me as I began reaching my goals and making new ones. FitGenie helped me reach my goals and transformed the way I eat. Thanks to the app, I live a more flexible and balanced life while feeling and looking my best!',
      before: '../images/landing_photo_kristen_before.webp',
      after: '../images/landing_photo_kristen_after.webp',
      change: '-58 lbs'
    }
  ]

  return <div className="grid grid-cols-1 gap-8 lg:gap-12 mx-auto xl:px-12 max-w-screen-xl md:grid-cols-2">
    {transformations.map(transformation => {
      return <div key={transformation.id} className="flex flex-col space-y-4">
        <HeroBody><span className="font-bold"><LocalizedTextLabel t={t} text={transformation.name}/></span></HeroBody>

        <div className="flex space-x-3">
          <div className="relative w-1/2">
            <div className="aspect-w-1 aspect-h-2">
              <img className="h-full w-full rounded-bl-xl rounded-tl-xl object-cover" src={transformation.before} />
            </div>

            <div className="bg-gradient-to-br from-fg-primary-gradient-start to-fg-primary absolute aspect-w-5 aspect-h-1 w-2/3 rounded-full top-5/6 left-1/2 transform -translate-x-1/2">
              <div className="flex items-center justify-center text-center">
                <HeroHeading2 className="text-white align-middle">{transformation.change}</HeroHeading2>
              </div>
            </div>
          </div>

          <div className="relative w-1/2">
            <div className="aspect-w-1 aspect-h-2">
              <img className="h-full w-full rounded-br-xl rounded-tr-xl object-cover" src={transformation.after} />
            </div>

            <div className="bg-white absolute aspect-w-5 aspect-h-1 w-2/3 rounded-full top-5/6 left-1/2 transform -translate-x-1/2">
              <div className="flex items-center justify-center text-center">
                <FitGenieLogoIcon className="w-3/4 h-full" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-3">
          <LandingQuoteLeftGraphic className=" h-12 w-12 lg:h-16 lg:w-16 xl:h-20 xl:w-20 flex-shrink-0" />
          <HeroBody className="flex-grow">
            <LocalizedTextLabel t={t} text={transformation.quote}/>
          </HeroBody>
        </div>
      </div>
    })}
  </div>
}